<template>
  <v-container>
    <ClientsComponent/>

  </v-container>
</template>

<script>
import ClientsComponent from "@/components/ClientsComponent.vue";

export default {
  name: "ClientsView",
  components: {ClientsComponent}
}
</script>

<style scoped>

</style>