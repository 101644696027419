<template>
<v-container>
  <ProduitsAllComponent/>
</v-container>
</template>

<script>
import ProduitsAllComponent from "@/components/ProduitsAllComponent.vue";

export default {
  name: "ProduitsAllView",
  components: {ProduitsAllComponent}
}
</script>

<style scoped>

</style>