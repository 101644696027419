<template>
  <v-container>
    <ClientSingleComponent/>
  </v-container>
</template>

<script>
import ClientSingleComponent from "@/components/ClientSingleComponent.vue";

export default {
  name: "ClientSingleView",
  components: {ClientSingleComponent}
}
</script>

<style scoped>

</style>