<template>
  <v-container fluid>
    <v-form @submit="loginmethod">
      <v-text-field
          v-model="login"
          label="Login"
      ></v-text-field>
      <v-text-field
          v-model="password"
          label="Mot de passe"
          :rules="passwordRules"
          type="password"
          @keyup.enter="loginmethod"
      ></v-text-field>
      <v-btn
          color="primary"
          @click="loginmethod"
      >Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "LoginComponent",
  data: () => ({
    login: null,
    password: null,
    passwordRules: [
      v => !!v || 'Le mot de passe est requis',
      v => (v && v.length >= 8) || 'Le mot de passe doit contenir au moins 8 caractères',
    ],
  }),
  methods: {
    loginmethod() {
      this.$store.dispatch('login', {login: this.login, password: this.password})
    }
  }
}
</script>

<style scoped>

</style>