<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <router-link to='/' style="text-decoration: none; color: inherit;">
        <div class="d-flex align-center" >
          <v-img
              alt="ABII Logo"
              class="shrink mr-2"
              contain
              src="@/assets/images/pinguin-logo.png"
              transition="scale-transition"
              width="40"
          />

          <v-toolbar-title>
            <span class="font-weight">ABII</span>
          </v-toolbar-title>

        </div>
      </router-link>


      <v-spacer></v-spacer>

      <!--Les routes-->
      <router-link to="/login" v-if="this.$store.state.user == null">
        <v-btn
            color="white"
            dark
            text
        >
          Login
        </v-btn>
      </router-link>

<!--      <router-link to="/" v-if="this.$store.state.user >= 2 ">
        <v-btn
            color="white"
            dark
            text
        >
          Carte
        </v-btn>
      </router-link>-->

      <router-link to="/clients" v-if="this.$store.state.user >= 2 ">
        <v-btn
            color="white"
            dark
            text
        >
          Clients
        </v-btn>
      </router-link>



      <router-link to="/produits" v-if="this.$store.state.user >= 2 ">
        <v-btn
            color="white"
            dark
            text
        >
          Produits
        </v-btn>
      </router-link>

      <router-link to="/logout" v-if="this.$store.state.user !==null ">
        <v-btn
            color="white"
            dark
            text
        >
          Logout
        </v-btn>
      </router-link>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  data: () => ({
  }),
    //get snackbar from state and set it to snackbar
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar
      },
      set(value) {
        this.$store.commit('setSnackbar', value)
      }
    },
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.$store.state.user = parseInt(localStorage.getItem('role'))
    }
  }
};
</script>
