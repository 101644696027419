<template>
<v-container>
  <ProduitComponent/>
</v-container>
</template>

<script>
import ProduitComponent from "@/components/Produit.vue";

export default {
  name: "ProduitView",
  components: {ProduitComponent}
}
</script>

<style scoped>

</style>