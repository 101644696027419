<template>
  <ProduitsComponent/>
</template>

<script>

  import ProduitsComponent from "@/components/Produits.vue";

  export default {
    name: 'HomeView',

    components: {
      ProduitsComponent

    },
  }
</script>
