<template>
  <v-container>
    <v-row>
      <v-col>
        <router-link to="/clients" style="text-decoration: none; color: inherit;">
          <v-btn color="red" style="text-decoration: none">
            <v-card-text>
              <v-icon>mdi-arrow-left</v-icon>
              Retour
            </v-card-text>
          </v-btn>
        </router-link>
      </v-col>
      <v-col>
        <v-btn color="yellow" style="text-decoration: none" @click="addAdhesion(6)">
          <v-card-text>
            <v-icon>mdi-arrow-left</v-icon>
            Adhesion 6 mois
          </v-card-text>
        </v-btn>
        <v-btn color="yellow" style="text-decoration: none" @click="addAdhesion(12)">
          <v-card-text>
            <v-icon>mdi-arrow-left</v-icon>
            Adhesion 12 mois
          </v-card-text>
        </v-btn>
      </v-col>
    </v-row>

    <ClientSearchComponent/>

    <v-container class="infoClient">
      <h1>{{ client.name }}</h1>
      <h3 style="color: black">Adhérent : <span style="color: dodgerblue">{{ getAdhesion(client.fin_adhesion) }}</span>
      </h3>
      <h3 style="color: black">Solde : <span style="color: dodgerblue">{{ Math.round(client.solde*100)/100 }}</span> €</h3>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="rechargementValue"
              label="Recharger"
              type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-btn color="green" style="text-decoration: none" @click="recharger">
            <v-card-text>
              <v-icon>mdi-plus</v-icon>
              Recharger
            </v-card-text>
          </v-btn>
        </v-col>

      </v-row>
    </v-container>
    <v-container>
      <h1>Achat</h1>

      <AchatComponent :adherent="getAdherent(client.fin_adhesion) " :client="client"></AchatComponent>

    </v-container>

    <v-container>
        <v-data-table
                :headers="headersAchats"
                :items="achats"
                :search="search"
                :loading="loading"
                :items-per-page="5"
                class="elevation-1"        >
        <template v-slot:[`item.date`]="{ item }">
          {{
            new Date(item.date).toLocaleDateString('fr-FR', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
          }}
        </template>
        <template v-slot:[`item.produit`]="{ item }">
          {{ item.produit.name }}
        </template>
        <template v-slot:[`item.quantite`]="{ item }">
          {{ item.quantite }}
        </template>
        <template v-slot:[`item.prix`]="{ item }">
          {{ item.montant_unitaire * item.quantite }} € ({{ item.montant_unitaire }} €/unité)
        </template>

        <template v-slot:[`item.actions`]="{ item }">
              <v-btn @click="supprimerAchat(item)" color="error" icon>
                  <v-icon>mdi-delete</v-icon>
              </v-btn>
        </template>

      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import AchatComponent from "@/components/AchatComponent.vue";
import ClientSearchComponent from "@/components/ClientSearchComponent.vue";

export default {
  name: "ClientSingleComponent",
  components: {ClientSearchComponent, AchatComponent},
  data() {
    return {
      headersAchats: [
        {text: "Date", value: "date"},
        {text: "Produit", value: "produit"},
        {text: "Quantité", value: "quantite"},
        {text: "Prix", value: "prix"},
        {text: "Actions", value: "actions"}
      ],
      search: "",
      loading: false,
      rechargementValue: 0,
      changeClient: null
    };
  },
  methods: {
    supprimerAchat(achat) {
      if (confirm("Voulez vous supprimer l'achat suivant ?\r" + achat.produit.name + " " + achat.quantite + " unité(s)")) {
        this.$store.dispatch("cancelAchat", achat);
      }
    },
    addAdhesion(nbMois) {

      if (confirm("Voulez vous rajouter " + nbMois + " mois d'adhésion ?")) {
        this.$store.dispatch("addAdhesion", {client_id: this.$route.params.id, month: nbMois});
      }

    },

    getAdhesion(date) {
      //date = 2023-04-28T16:46:18.619Z
      const dateNow = new Date();
      const dateFinAdhesion = new Date(date);
      if (dateNow > dateFinAdhesion) {
        return "Non";
      } else {
        const diffTime = Math.abs(dateFinAdhesion - dateNow);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return `Oui (${diffDays}j restants )`;
      }
    },
    getAdherent(date) {
      const dateNow = new Date();
      const dateFinAdhesion = new Date(date);
      if (dateNow > dateFinAdhesion) {
        return false;
      } else {
        return true;
      }
    },
    recharger() {
      if (this.rechargementValue != 0) {
        if (confirm("Voulez vous recharger " + this.rechargementValue + " € ?")) {
          this.$store.dispatch("addRechargement", {client_id: this.$route.params.id, montant: this.rechargementValue});
          this.rechargementValue = 0;
        }
      }
      else {
        alert("Veuillez saisir un montant");
      }
    },
  },
  computed: {
    client() {
      return this.$store.getters.client;
    },
    achats() {
      return this.$store.getters.achats;
    },
    clients()
    {
      return this.$store.getters.clients;
    }
  },
  created() {
    this.$store.dispatch("fetchClient", this.$route.params.id);
    this.$store.dispatch("fetchClients");
  },
}
</script>

<style scoped>
.infoClient {
  border: 1px solid black;

}
</style>