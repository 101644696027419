import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

//import {API_BASE} from "@/apibase";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        produits: [],
        snackbar: {
            show: false,
            color: 'success',
            text: "",
            timeout: 3000


        },
        clients: [],
        client: {},
        categories: [],
        achats: []
    },
    getters: {
        produits: state => state.produits,
        user: state => state.user,
        snackbar: state => state.snackbar,
        clients: state => state.clients,
        client: state => state.client,
        categories: state => state.categories,
        achats: state => state.achats
    },
    mutations: {
        setProduits(state, produits) {
            state.produits = produits;
        },
        setUser(state, user) {
            state.user = user;
        },
        setSnackbar(state, snackbar) {
            state.snackbar = snackbar;
        },
        setClients(state, clients) {
            clients.map(client => {
                //arrondi à 2 chiffres après la virgule
                client.solde = Math.round(client.solde * 100) / 100;
            });
            state.clients = clients;
        },
        setClient(state, client) {
            state.client = client;
        },
        produitsHidden(state, hidden) {
            state.produits = state.produits.filter(produit => produit.hidden !== hidden);
        },
        setCategories(state, categories) {
            state.categories = categories;
        },
        setAchats(state, achats) {
            state.achats = achats;
        }
    },
    actions: {
        //auth
        async login({commit}, user) {
            await fetch('https://api.abii.thomas-dev.com/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
                .then(response => response.json())
                .then(data => {
                    //si la connexion est réussie, on stocke le token dans le localStorage
                    if (data.success) {
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('role', data.role_id);
                        //date + 1 heure
                        localStorage.setItem('time', Date.now() + (6000));
                        commit('setUser', data.role_id);
                        if (data.role_id >= 2) {
                            router.push({name: 'clients'});

                        } else {
                            router.push({name: '/'});
                        }
                    } else {
                        commit('setSnackbar', {
                            show: true,
                            color: data.success ? 'success' : 'error',
                            text: data.message ? data.message : "Erreur de connexion",
                            timeout: 3000
                        });
                    }
                });
        },
        async signup({commit}, user) {
            //on verifie que les mots de passe sont identiques
            if (user.password !== user.password2) {
                alert('Les mots de passe ne sont pas identiques');
                return;
            } else {
                await fetch('https://api.abii.thomas-dev.com/api/auth/signup', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(
                        {
                            login: user.login,
                            password: user.password
                        }
                    )
                })
                    .then(response => response.json())
                    .then(data => {
                        //affiche le message de retour en snack bar
                        commit('setSnackbar', {
                            show: true,
                            color: data.success ? 'success' : 'error',
                            text: data.message,
                            timeout: 3000
                        });
                    });
            }
        },
        //produits
        async fetchProduits({commit}) {
            const response = await fetch('https://api.abii.thomas-dev.com/api/produits');
            const data = await response.json();
            commit('setProduits', data);
        },
        async updateProduit({commit, dispatch}, produit) {
            await fetch('https://api.abii.thomas-dev.com/api/produits/' + produit.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
                body: JSON.stringify(produit)
            })
                .then(response => response.json())
                .then(data => {
                    //affiche le message de retour en snack bar
                    commit('setSnackbar', {
                        show: true,
                        color: data.success ? 'success' : 'error',
                        text: data.message,
                        timeout: 3000
                    });
                    dispatch('fetchProduits');
                });
        },
        async createProduit({commit, dispatch}, produit) {
            await fetch('https://api.abii.thomas-dev.com/api/produits/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
                body: JSON.stringify(produit)
            })
                .then(response => response.json())
                .then(data => {
                    //affiche le message de retour en snack bar
                    commit('setSnackbar', {
                        show: true,
                        color: data.success ? 'success' : 'error',
                        text: data.message,
                        timeout: 3000
                    });
                    dispatch('fetchProduits');
                });
        },
        //fetch produits when socket event is received with Vue-Socket.io
        async "SOCKET_ProductUpdate"({dispatch}) {
            dispatch('fetchProduits');
        },

        //clients
        async addClient({commit, dispatch}, client) {
            await fetch('https://api.abii.thomas-dev.com/api/clients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
                body: JSON.stringify({
                    nom: client,
                })
            })
                .then(response => response.json())
                .then(data => {
                    //affiche le message de retour en snack bar
                    commit('setSnackbar', {
                        show: true,
                        color: data.success ? 'success' : 'error',
                        text: data.message,
                        timeout: 3000
                    });
                    dispatch('fetchClients');
                });

        },
        async fetchClients({commit}) {
            await fetch('https://api.abii.thomas-dev.com/api/clients', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                }
            })
                .then(response => response.json())
                .then(data => {
                    if (data.error == 0) {
                        router.push({name: 'login'});
                    }
                    commit('setClients', data);
                });

        },

        //client
        async fetchClient({commit, dispatch}, id) {
            await fetch('https://api.abii.thomas-dev.com/api/clients/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                }
            })
                .then(response => response.json())
                .then(data => {
                    commit('setClient', data);
                    dispatch('fetchAchats', id);
                });
        },

        //categories
        async fetchCategories({commit}) {
            await fetch('https://api.abii.thomas-dev.com/api/type_produits', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                }
            })
                .then(response => response.json())
                .then(data => {
                    commit('setCategories', data);
                });
        },

        //achats
        async newAchat({commit, dispatch}, panier) {
            await fetch('https://api.abii.thomas-dev.com/api/achats', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
                body: JSON.stringify({
                    user_id: panier.user_id,
                    contenuAchat: panier.panier

                })
            })
                .then(response => response.json())
                .then(data => {
                    //affiche le message de retour en snack bar
                    commit('setSnackbar', {
                        show: true,
                        color: data.success ? 'success' : 'error',
                        text: data.message,
                        timeout: 3000
                    });
                    dispatch('fetchClients');
                    dispatch('fetchClient', panier.user_id);
                    dispatch('fetchProduits');
                    dispatch('fetchAchats');
                });
        },
        async cancelAchat({dispatch}, achat) {
            await fetch('https://api.abii.thomas-dev.com/api/achats/'+achat.id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
            })
                .then(response => response.json())
                .then(() =>{
                    dispatch('fetchClients');
                    dispatch('fetchClient', achat.client_id);
                    dispatch('fetchProduits');
                    dispatch('fetchAchats');

                });
        },
        async fetchAchats({commit}, idClient) {
            await fetch('https://api.abii.thomas-dev.com/api/achats/' + idClient, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                }
            })
                .then(response => response.json())
                .then(data => {
                    data.sort((a, b) => {
                        return new Date(b.date) - new Date(a.date);
                    });
                    commit('setAchats', data);
                });
        },

        //add Adhesion
        async addAdhesion({dispatch}, adhesion) {
            await fetch('https://api.abii.thomas-dev.com/api/adhesion', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
                body: JSON.stringify({
                    client_id: adhesion.client_id,
                    month: adhesion.month
                })
            })
                .then(() => {
                    dispatch('fetchClient', adhesion.client_id);
                });

        },

        //add Rechargement
        async addRechargement({dispatch}, rechargement) {
            await fetch('https://api.abii.thomas-dev.com/api/rechargements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')

                },
                body: JSON.stringify({
                    clientId: rechargement.client_id,
                    //converti le montant en float
                    montant: parseFloat(rechargement.montant)
                })
            })
                .then(() => {
                    dispatch('fetchClient', rechargement.client_id);
                });
        }
    }
})
