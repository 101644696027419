<template>
  <v-container fluid>
    <v-form>
      <v-text-field
          v-model="login"
          label="Login"
      ></v-text-field>
      <v-text-field
          v-model="password"
          label="Mot de passe"
          :rules="passwordRules"
          type="password"
      ></v-text-field>
      <v-text-field
          v-model="password2"
          label="Confirmer le mot de passe"
          :rules="passwordRules"
          type="password"
      ></v-text-field>
      <v-btn
          color="primary"
          @click="signupMethod"
          :disabled="!(this.login && this.password && this.password2 && this.password === this.password2 && this.password.length >= 8 && this.password2.length >= 8)"
      >Signup</v-btn>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "SignupComponent",
  data: () => ({
    login: null,
    password: null,
    password2: null,
    passwordRules: [
      v => !!v || 'Le mot de passe est requis',
      v => (v && v.length >= 8) || 'Le mot de passe doit contenir au moins 8 caractères',
    ],
  }),
  methods: {
    signupMethod() {
      this.$store.dispatch('signup', {login: this.login, password: this.password, password2: this.password2})
    }
  }
}
</script>

<style scoped>

</style>