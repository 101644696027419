<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col
          v-for="produit in produits.filter(produit => (produit.stock > 0 && produit.hidden === false)).sort((a, b) => (a.type_produit_id > b.type_produit_id) ? 1 : -1)"
          :key="produit.id"
          cols="12" sm="12" md="6" lg="2">

       <v-img :src="`${produit.image}`" aspect-ratio="1" cover
              lazy-src="https://pusat.edu.np/wp-content/uploads/2022/09/default-image.jpg"
              class="bg-grey-lighten-2 text-white align-end rounded" :title="updateProduit.nom">
         <template v-slot:placeholder>
           <v-row class="fill-height ma-0" align="center" justify="center">
             <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
           </v-row>
         </template>
         <v-card-title style="background: rgba(0, 0, 0, 0.5);color:white">
           {{ produit.name }}
         </v-card-title>
         <v-card-text style="background: rgba(0, 0, 0, 0.5); color:white" class="d-flex w-100">
           <p>
             <v-icon color="green">mdi-account-check</v-icon>
             {{ produit.prix_adherent }} €
           </p>
           <v-spacer></v-spacer>
           <p>
             <v-icon color="red">mdi-account-remove</v-icon>
             {{ produit.prix_non_adherent }} €
           </p>
           <v-spacer></v-spacer>
           <p>
             <v-icon>mdi-cart</v-icon>
             {{ produit.stock }}
           </p>
         </v-card-text>


       </v-img>
     </v-col>
    </v-row>


  </v-container>

</template>

<script>
import {mapGetters} from "vuex";


export default {
  name: "ProduitsComponent",
  data: () => ({
    updateProduit: {},

  }),
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['produits', 'categories'])
  },
  methods: {
    SauvegarderProduit() {
     if (this.updateProduit.id) {
       this.$store.dispatch('updateProduit', this.updateProduit)
     }
     else {
       this.$store.dispatch('createProduit', this.updateProduit)
     }
     this.$socket.emit('updateProduit', this.updateProduit)
    }


  },
  created() {
    this.$store.dispatch('fetchProduits')
    this.$store.dispatch('fetchCategories')
  }
}
</script>

<style scoped>

</style>