<template>
  <v-container>
    <v-tabs
        v-model="tab"
        bg-color="primary"
    >
      <v-tab value="login">Login</v-tab>
      <v-tab value="signup">Signup</v-tab>
    </v-tabs>
    <LoginComponent v-if="tab==0"/>
    <SignupComponent v-if="tab==1"/>
  </v-container>
</template>

<script>
import LoginComponent from "@/components/Login.vue";
import SignupComponent from "@/components/Signup.vue";


export default {
  name: "AuthView",
  components: {SignupComponent, LoginComponent},
  data: () => ({
    tab: null
  }),
}
</script>

<style scoped>

</style>