<template>
  <v-autocomplete
      v-model="searchClient"
      :items="clients"
      item-text="name"
      dense
      filled
      label="Rechercher un client"
      auto-select-first
      class="pt-5"
      @change="selectClient"
      return-object
  ></v-autocomplete>

</template>

<script>
export default {
  name: "ClientSearchComponent.vue",
  data() {
    return {
      searchClient: "",
    }
  },
  computed: {
    clients() {
      return this.$store.getters.clients;
    }
  },
  methods: {
    selectClient() {
      this.$store.dispatch("fetchClient", this.searchClient.id);
      this.$router.push({name: "client", params: {id: this.searchClient.id}});    }
  },
  created() {
    this.$store.dispatch("fetchClients");
  }
}
</script>

<style scoped>

</style>