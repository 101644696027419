<template>
  <v-container>

  </v-container>
</template>

<script>
export default {
  name: "ProduitComponent"
}
</script>

<style scoped>

</style>