<template>
  <v-container fluid>
    <v-row justify="center">
      <v-btn
          color="primary"
          @click="dialog = true; updateProduit= {}"
      >
        Créer un article
      </v-btn>
      <v-dialog
          v-model="dialog"

          width="1024"
      >



        <v-card>
          <v-card-title>
            <span class="text-h5" @click="dialog = true; updateProduit= {}">Modifier/créer un article</span>
          </v-card-title>
          <v-card-text>
            <v-container>

              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-icon @click="updateProduit={}">
                    mdi-refresh
                  </v-icon>
                  <v-text-field
                      label="Nom du produit"
                      v-model="updateProduit.name"
                      required
                  ></v-text-field>
                  <v-text-field
                      label="Prix Prime"
                      v-model="updateProduit.prix_adherent"
                      required
                      type="number"
                      prefix="€"
                  ></v-text-field>
                  <v-text-field
                      label="Prix standard"
                      v-model="updateProduit.prix_non_adherent"
                      required
                      type="number"
                      prefix="€"
                  ></v-text-field>
                  <v-text-field
                      label="Stock"
                      v-model="updateProduit.stock"
                      required
                      type="number"
                      prepend-icon="mdi-cart"
                  ></v-text-field>
                  <v-text-field
                      label="Nom de l'image"
                      v-model="updateProduit.image"
                      required
                      prepend-icon="mdi-image"
                  ></v-text-field>
                  <v-text-field
                      label="Code barre"
                      v-model="updateProduit.barcode"
                      required
                      prepend-icon="mdi-barcode"
                  ></v-text-field>
                  <v-switch label="Masquer sur la carte" v-model="updateProduit.hidden"></v-switch>
                  <v-select
                      v-model="updateProduit.type_produit_id"
                      :items="categories"
                      item-text="libelle"
                      item-value="id"
                      label="Catégories"
                      persistent-hint
                      single-line
                      required
                  ></v-select>


                </v-col>
                <v-col cols="12" sm="6" md="4">

                  <v-img :src="`${updateProduit.image}`" aspect-ratio="1" cover
                         lazy-src="https://pusat.edu.np/wp-content/uploads/2022/09/default-image.jpg"
                         class="bg-grey-lighten-2 text-white align-end rounded" :title="updateProduit.nom">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                    <v-card-title style="background: rgba(0, 0, 0, 0.5);color:white">
                      {{ updateProduit.name }}
                    </v-card-title>
                    <v-card-text style="background: rgba(0, 0, 0, 0.5); color:white" class="d-flex w-100">
                      <p>
                        <v-icon color="green">mdi-account-check</v-icon>
                        {{ updateProduit.prix_adherent }} €
                      </p>
                      <v-spacer></v-spacer>
                      <p>
                        <v-icon color="red">mdi-account-remove</v-icon>
                        {{ updateProduit.prix_non_adherent }} €
                      </p>
                      <v-spacer></v-spacer>
                      <p>
                        <v-icon>mdi-cart</v-icon>
                        {{ updateProduit.stock }}
                      </p>
                    </v-card-text>


                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue-darken-1"
                variant="text"
                @click="dialog = false ; updateProduit= {}"
            >
              Fermer
            </v-btn>
            <v-btn
                color="blue-darken-1"
                variant="text"
                @click="SauvegarderProduit()"
            >
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>


    <v-autocomplete
        v-model="updateProduit"
        :items="produits"
        item-text="name"
        return-object
        label="Modifier un produit"
        @change="dialog = true ;"

    >

    </v-autocomplete>

    <v-data-table
        :headers="headersProducts"
        :items="produits"
        @click:row="updateProduit = $event; dialog = true"
        class="elevation-1"
        :dense="dense"
        :hide-headers="hideHeaders"

    >
    </v-data-table>


  </v-container>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProduitsAllComponent",
  data: () => ({
    dialog: false,
    updateProduit: {},
    headersProducts: [
      {text: 'Nom', value: 'name'},
      {text: 'Prix Prime', value: 'prix_adherent'},
      {text: 'Prix standard', value: 'prix_non_adherent'},
      {text: 'Stock', value: 'stock'},
      {text: 'Image', value: 'image'},
      {text: 'Code barre', value: 'barcode'},
      {text: 'hidden', value: 'hidden'},
      {text: 'Catégorie', value: 'type_produit_id'},
    ],
    dense: false,
    hideHeaders: false,
  }),
  props: {
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['produits', 'categories'])
  },
  methods: {
    SauvegarderProduit() {
      if (this.updateProduit.id) {
        this.$store.dispatch('updateProduit', this.updateProduit)
      } else {
        this.$store.dispatch('createProduit', this.updateProduit)
      }
      this.dialog = false
    },

    handleResize() {
      if (window.innerWidth < 768) {
        this.dense = true;
        this.hideHeaders = true;
        this.headersProducts = [
          {text: 'Nom', value: 'name'},
          {text: 'Prix Prime', value: 'prix_adherent'},
          {text: 'Prix standard', value: 'prix_non_adherent'},
          {text: 'Stock', value: 'stock'}
        ];


      } else {
        this.dense = false;
        this.hideHeaders = false;
        this.headersProducts = [
          {text: 'Nom', value: 'name'},
          {text: 'Prix Prime', value: 'prix_adherent'},
          {text: 'Prix standard', value: 'prix_non_adherent'},
          {text: 'Stock', value: 'stock'},
          {text: 'Image', value: 'image'},
          {text: 'Code barre', value: 'barcode'},
          {text: 'hidden', value: 'hidden'},
          {text: 'Catégorie', value: 'type_produit_id'},
        ];
      }
    },


  },
  created() {
    this.$store.dispatch('fetchProduits')
    this.$store.dispatch('fetchCategories')
  }
  ,
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUpdate() {
    this.handleResize();
  },
}
</script>

<style scoped>

</style>