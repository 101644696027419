import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from "@/views/AuthView.vue";
import ClientsView from "@/views/ClientsView.vue";
import ClientSingleView from "@/views/ClientSingleView.vue";
import ProduitView from "@/views/ProduitView.vue";
import ProduitsAllView from "@/views/ProduitsAllView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: AuthView
    },
    {
        path: '/logout',
        name: 'logout',
        //suppression du token
        beforeEnter: (to, from, next) => {
            localStorage.removeItem('token')
            //set state user to null
            router.app.$store.commit('setUser', null)
            next('/login')
        }
    },
    {
        path: '/clients',
        name: 'clients',
        component: ClientsView,
        meta: {
            authRequired: true
        }
    },
    {
        path:'/client/:id',
        name: 'client',
        component: ClientSingleView,
        meta: {
            authRequired: true
        }
    },

    {
        path: '/produits/',
        name: 'produits',
        component: ProduitsAllView,
        meta: {
            authRequired: true
        }

    },
    {
        path: '/produit/:id',
        name: 'produit',
        component: ProduitView,
        meta: {
            authRequired: true
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    //console.log("Nav to " + to.path + " from " + from.path + " is auth required ? " + JSON.stringify(to.meta))
    if (to.authRequired && !localStorage.getItem('token') && !localStorage.getItem('role') === 3) {
        next('/login')
    } else {
        next()
    }
})





export default router
