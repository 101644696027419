import { render, staticRenderFns } from "./Produit.vue?vue&type=template&id=84c87a26&scoped=true&"
import script from "./Produit.vue?vue&type=script&lang=js&"
export * from "./Produit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84c87a26",
  null
  
)

export default component.exports