<template>
  <v-container>


    <v-container >
      <v-simple-table>
        <thead>
        <tr>
          <th class="text-left">
            Produit
          </th>
          <th class="text-left">
            Quantité
          </th>
          <th class="text-left">
            Prix
          </th>
          <th class="text-left">
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="produit in panier"
            :key="produit.produit_id"
        >
          <td>{{ produit.produit.name }}</td>
          <td>{{ produit.quantite }}</td>
          <td>{{ produit.produit.prix * produit.quantite }} € ({{ produit.produit.prix }} x {{ produit.quantite }})</td>
          <td>
            <v-btn variant="outlined" size="x-small" icon color="info" class="me-2" @click="addPanier(produit.produit)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn variant="outlined" size="x-small" icon color="red" @click="deleteFromPanier(produit.idProduit)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
     <v-row>
       <v-col>
         <v-btn v-if="panier.length > 0" class="me-auto" color="success" @click="validatePanier">
           Valider
         </v-btn>
       </v-col>
        <v-col>
          <v-btn v-if="panier.length > 0" class="me-auto" color="error" @click="panier=[]">
            Vider le panier
          </v-btn>
        </v-col>
     </v-row>
    </v-container>

      <v-text-field
          v-model="produitSearch"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          style="margin-bottom: 10px"
      />

      <v-row dense>
        <v-col
            v-for="produit in produits.filter(produit => (produit.stock > 0 && produit.hidden === false && produit.name.includes(this.produitSearch))).sort((a, b) => (a.type_produit_id > b.type_produit_id) ? 1 : -1)"
            :key="produit.name"
            cols="6"
            md="3"
            sm="1"
        >
          <v-img :src="`${produit.image}`" aspect-ratio="1" cover
                 lazy-src="https://pusat.edu.np/wp-content/uploads/2022/09/default-image.jpg"
                 @click="addPanier(produit)"
                 class="bg-grey-lighten-2 text-white align-end rounded" :title="produit.name">
            <v-card-title style="background: rgba(0, 0, 0, 0.5);color:white">
              {{ produit.name }}
            </v-card-title>
            <v-card-text style="background: rgba(0, 0, 0, 0.5); color:white" class="d-flex w-100">
              <p v-if="adherent">
                <v-icon color="green">mdi-account-check</v-icon>
                {{ produit.prix_adherent }} €
              </p>
              <p v-else>
                <v-icon color="red">mdi-account-remove</v-icon>
                {{ produit.prix_non_adherent }} €
              </p>
              <v-spacer></v-spacer>
              <p>
                <v-icon color="warning">mdi-cart</v-icon>
                {{ produit.stock }}
              </p>
            </v-card-text>


          </v-img>
        </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {
  name: "AchatComponent",
  data() {
    return {
      panier: [],
      produitSearch: '',
    };
  },
  props: {
    adherent: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addPanier(produit) {
        const index = this.panier.findIndex(panier => panier.idProduit === produit.id);

        if (index === -1) {
          if (this.adherent) {
            produit.prix = produit.prix_adherent;
          } else {
            produit.prix = produit.prix_non_adherent;
          }
          if (produit.stock > 0) {
            this.panier.push({idProduit: produit.id, quantite: 1, produit: produit});
            this.$store.commit('setSnackbar', {
              show: true,
              color: 'success',
              text: "Produit ajouté au panier !",
              timeout: 500
            });
          }
        } else {
          if (this.panier[index].quantite < produit.stock) {
            this.panier[index].quantite++;
            this.$store.commit('setSnackbar', {
              show: true,
              color: 'success',
              text: "Produit ajouté au panier !",
              timeout: 500
            });
          }
        }


    },
    deleteFromPanier(idProduit) {
      const index = this.panier.findIndex(panier => panier.idProduit == idProduit);
      if (index !== -1) {
        if(this.panier[index].quantite > 1) {
          this.panier[index].quantite--;
        } else {
          this.panier.splice(index, 1);
        }
      }
      },
    validatePanier() {
        const panierToSend = {
          user_id: this.client.id,
          panier: this.panier,
        }
        this.$store.dispatch("newAchat", panierToSend);
        this.panier = [];

    },

  },
  computed: {
    produits() {
      return this.$store.getters.produits.filter(produit => produit.stock > 0);
    },
  },
  created() {
    this.$store.dispatch("fetchProduits");
  }
}
</script>

<style scoped>

</style>