<template>
  <v-container>

    <v-container v-if="addNewClient">
      <v-text-field
          v-model="newClient"
          label="Client"
          required
      ></v-text-field>
      <v-btn color="primary" @click="addClient"
             :disabled="newClient === ''"
      >Ajouter un Client
      </v-btn>
    </v-container>
    <v-btn @click="addNewClient = !addNewClient" :color="addNewClient ? 'red' : 'primary'">
      <v-icon>{{ addNewClient ? "mdi-close" : "mdi-plus"}}</v-icon>
      {{ addNewClient ? "Annuler" : "Ajouter un client"}}
    </v-btn>

    <ClientSearchComponent/>

    <v-data-table
        :headers="headers"
        :items="clients"
        :items-per-page="10"
        class="elevation-1"
        @click:row="searchClient = $event; selectClient()"
    >
      <template v-slot:[`item.fin_adhesion`]="{ item }">
        <v-chip :color="getAdhesion(item.fin_adhesion) === 'Non' ? 'red' : 'green'">
          {{ getAdhesion(item.fin_adhesion) }}
        </v-chip>
      </template>
      <template v-slot:[`item.solde`]="{ item }">
        <v-chip
        :color="item.solde >= 0 ? 'green' : 'red'"
        >
          {{ item.solde }} €
        </v-chip>
      </template>
    </v-data-table>
  </v-container>


</template>

<script>
import ClientSearchComponent from "./ClientSearchComponent";
export default {
  name: "ClientsComponent",
  components: {ClientSearchComponent},
  data() {
    return {
      addNewClient: false,
      newClient: "",
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'id',
        },
        {text: 'Nom', value: 'name'},
        {text: 'Solde', value: 'solde'},
        {text: 'Adherent', value: 'fin_adhesion'}
      ],
      searchClient: null,
    };
  },
  methods: {
    addClient() {
      if (this.newClient === "") return;
      this.$store.dispatch("addClient", this.newClient);
      this.newClient = "";
    },
    getAdhesion(date) {
      //date = 2023-04-28T16:46:18.619Z
      const dateNow = new Date();
      const dateFinAdhesion = new Date(date);
      if (dateNow > dateFinAdhesion) {
        return "Non";
      } else {
        const diffTime = Math.abs(dateFinAdhesion - dateNow);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return `Oui (${diffDays}j restants )`;
      }
    }
  },
  created() {
    this.$store.dispatch("fetchClients");
  },
  computed: {
    clients() {
      return this.$store.getters.clients;
    },
  },
}
</script>

<style scoped>

</style>